import utils from "@mindbush/artomos-shared-repo/utils";
import {
  statsTypes,
  userStats_userDailyReportListTypes,
  userStats_userMonthlyReportListTypes,
  agentStats_dailyReportListTypes,
  agentStats_monthlyReportListTypes,
} from "@mindbush/artomos-shared-repo/schemas";
import {
  GET_STATS_REQUEST,
  GET_STATS_SUCCESS,
  GET_STATS_FAILURE,
  SELECT_USER_STATS_MONTHLY_STATS,
  SELECT_USER_STATS_DAILY_STATS,
  CLEAR_SELECTED_USER_DAILY_STATS,
  CLEAR_SELECTED_USER_MONTHLY_STATS,
  SELECT_AGENT_STATS_DAILY_STATS,
  SELECT_AGENT_STATS_MONTHLY_STATS,
  CLEAR_SELECTED_AGENT_DAILY_STATS,
  CLEAR_SELECTED_AGENT_MONTHLY_STATS,
} from "../types/statsTypes";
import * as AUTH_TYPES from "../types/authTypes";

// =======================================
// Schemas
// =======================================

const platformStatsSchema = {
  type: ["null", "object"],
  strict: true,
  properties: { ...statsTypes.properties },
};

const selectedUserDailyStatsSchema = {
  type: ["null", "object"],
  strict: true,
  properties: { ...userStats_userDailyReportListTypes.items.properties },
};

const selectedUserMonthlyStatsSchema = {
  type: ["null", "object"],
  strict: true,
  properties: { ...userStats_userMonthlyReportListTypes.items.properties },
};

const selectedAgentDailyStatsSchema = {
  type: ["null", "object"],
  strict: true,
  properties: { ...agentStats_dailyReportListTypes.items.properties },
};

const selectedAgentMonthlyStatsSchema = {
  type: ["null", "object"],
  strict: true,
  properties: { ...agentStats_monthlyReportListTypes.items.properties },
};

const statsStateSchema = {
  type: "object",
  properties: {
    error: { type: ["string", Error] },
    loading: { type: "boolean" },
    platformStats: platformStatsSchema,
    selectedUserDailyStats: selectedUserDailyStatsSchema,
    selectedUserMonthlyStats: selectedUserMonthlyStatsSchema,
    selectedAgentDailyStats: selectedAgentDailyStatsSchema,
    selectedAgentMonthlyStats: selectedAgentMonthlyStatsSchema,
  },
};

// =======================================
// Reducer
// =======================================

const INITIAL_STATE = {
  error: "",
  loading: false,
  platformStats: null,
  selectedUserDailyStats: null,
  selectedUserMonthlyStats: null,
  selectedAgentDailyStats: null,
  selectedAgentMonthlyStats: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  utils.validateReduxSchema(statsStateSchema, state);

  switch (action.type) {
    case GET_STATS_REQUEST:
      return { ...state, loading: true };
    case GET_STATS_SUCCESS:
      return { ...state, loading: false, platformStats: action.payload };
    case GET_STATS_FAILURE:
      return { ...state, loading: false, error: action.error };

    case SELECT_USER_STATS_DAILY_STATS:
      return {
        ...state,
        loading: false,
        selectedUserDailyStats: action.payload,
      };
    case CLEAR_SELECTED_USER_DAILY_STATS:
      return { ...state, loading: false, selectedUserDailyStats: null };

    case SELECT_USER_STATS_MONTHLY_STATS:
      return {
        ...state,
        loading: false,
        selectedUserMonthlyStats: action.payload,
      };
    case CLEAR_SELECTED_USER_MONTHLY_STATS:
      return { ...state, loading: false, selectedUserMonthlyStats: null };

    case SELECT_AGENT_STATS_DAILY_STATS:
      return {
        ...state,
        loading: false,
        selectedAgentDailyStats: action.payload,
      };
    case CLEAR_SELECTED_AGENT_DAILY_STATS:
      return { ...state, loading: false, selectedAgentDailyStats: null };

    case SELECT_AGENT_STATS_MONTHLY_STATS:
      return {
        ...state,
        loading: false,
        selectedAgentMonthlyStats: action.payload,
      };
    case CLEAR_SELECTED_AGENT_MONTHLY_STATS:
      return { ...state, loading: false, selectedAgentMonthlyStats: null };

    case AUTH_TYPES.SIGN_OUT_SUCCESS:
    case AUTH_TYPES.SET_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default reducer;
