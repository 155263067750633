export const GET_STATS_REQUEST = 'GET_STATS_REQUEST';
export const GET_STATS_SUCCESS = 'GET_STATS_SUCCESS';
export const GET_STATS_FAILURE = 'GET_STATS_FAILURE';

export const SELECT_USER_STATS_DAILY_STATS = 'SELECT_USER_STATS_DAILY_STATS';
export const SELECT_USER_STATS_MONTHLY_STATS = 'SELECT_USER_STATS_MONTHLY_STATS';
export const CLEAR_SELECTED_USER_DAILY_STATS = 'CLEAR_SELECTED_USER_DAILY_STATS';
export const CLEAR_SELECTED_USER_MONTHLY_STATS = 'CLEAR_SELECTED_USER_MONTHLY_STATS';

export const SELECT_AGENT_STATS_DAILY_STATS = 'SELECT_AGENT_STATS_DAILY_STATS';
export const SELECT_AGENT_STATS_MONTHLY_STATS = 'SELECT_AGENT_STATS_MONTHLY_STATS';
export const CLEAR_SELECTED_AGENT_DAILY_STATS = 'CLEAR_SELECTED_AGENT_DAILY_STATS';
export const CLEAR_SELECTED_AGENT_MONTHLY_STATS = 'CLEAR_SELECTED_AGENT_MONTHLY_STATS';